<template>
  <div class="news-detail">
    <el-form ref="postForm" :rules="rules" :model="postForm" label-width="160px" size="medium">
      <el-form-item label="计划名称:" class="title" prop="name">
        <el-input v-model="postForm.name" maxlength="15" show-word-limit></el-input>
      </el-form-item>
      <el-form-item label="选择应用:" class="title" prop="app_id">
        <el-select v-model="postForm.app_id" placeholder="请选择">
          <el-option
            v-for="item in appOptions"
            :key="item.app_id"
            :label="item.app_name"
            :value="item.app_id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="推广话术:" class="desc" prop="intro">
        <el-input
          v-model="postForm.intro"
          type="textarea"
          placeholder="请输入内容"
          maxlength="150"
          :autosize="{ minRows: 2, maxRows: 5 }"
          show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item label="推广话术(英文):" class="desc" prop="intro_en">
        <el-input
          v-model="postForm.intro_en"
          type="textarea"
          placeholder="请输入内容"
          maxlength="150"
          :autosize="{ minRows: 2, maxRows: 5 }"
          show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item label="生效日期(utc):" class="desc" prop="active_time">
        <el-date-picker
          v-model="postForm.active_time"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          :picker-options="activeTimeOption"
        />
      </el-form-item>
      <el-form-item label="奖励有效期(utc):" class="desc" prop="reward_validity_type">
        <el-radio-group v-model="postForm.reward_validity_type">
          <el-radio :label="1"
            >截止至
            <el-date-picker
              v-model="postForm.reward_expiry_at"
              type="date"
              placeholder="截止日期"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              :picker-options="expireTimeOption"
          /></el-radio>
          <el-radio :label="2">永久有效</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="新用户类型:" prop="invite_type">
        <el-radio-group v-model="postForm.invite_type">
          <el-radio :label="1">平台新用户</el-radio>
          <el-radio :label="2">应用新用户</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="奖励方式:" prop="reward_type">
        <el-radio-group v-model="postForm.reward_type">
          <el-radio :label="1">消费返佣</el-radio>
          <el-radio :label="2">注册固定奖励</el-radio>
          <el-radio :label="3">消费固定奖励</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        label="奖励金额单位:"
        prop="reward_currency"
        v-if="postForm.reward_type != 1"
        :rules="[{ required:postForm.reward_type != 1, message: '奖励金额单位必填', trigger: 'change' }]"
      >
        <el-select v-model="postForm.reward_currency" placeholder="请选择">
          <el-option
            v-for="item in coins"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="奖励规则:" prop="reward_rules">
        <AddRule
          :isPrecent="postForm.reward_type == 1"
          :disable="disable"
          :rewardRules="postForm.reward_rules"
          @changeRule="onChangeRule"
        />
      </el-form-item>
      <el-form-item label="详细说明:" prop="content">
        <tinymce v-model="postForm.content" id="tinymce-content" :height="300" />
      </el-form-item>
      <el-form-item label="详细说明(英文):" prop="content_en">
        <tinymce v-model="postForm.content_en" id="tinymce-content-en" :height="300" />
      </el-form-item>
      <el-form-item>
        <el-button @click="onCancel">{{ disable ? "返回" : "取消" }}</el-button>
        <el-button type="primary" :loading="loading" @click="onSubmit" :disabled="disable">
          提交
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import Tinymce from "@/components/Tinymce/index.vue";
import AddRule from "./addRule.vue";
import { CoinMap } from "@/utils/helper.js";
import { getPublishedApp, getPlanInfo, createPlan, editPlan } from "@/api/developer.js";

export default {
  components: {
    Tinymce,
    AddRule,
  },
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    disable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    var checkRule = (rule, value, callback) => {
      console.log(rule, value, callback);
      if (!value) {
        return callback(new Error("规则必填不能为空"));
      }
      callback();
    };
    return {
      id: "",
      postForm: {
        name: "",
        app_id: "",
        intro: "",
        intro_en: "",
        active_time: [],
        reward_validity_type: "",
        reward_expiry_at: "",
        invite_type: 1,
        reward_type: 1,
        reward_rules: [],
        content: "",
        content_en: "",
        reward_currency: "",
      },
      appOptions: [],
      coins: CoinMap,
      loading: false,
      rules: {
        name: [
          {
            required: true,
            message: "计划名称必填，且不超过15个字",
            trigger: "change",
          },
        ],
        app_id: [{ required: true, message: "请选择应用", trigger: "change" }],
        active_time: [{ required: true, message: "生效时间必填", trigger: "change" }],
        reward_validity_type: [{ required: true, message: "奖励有效期必填", trigger: "change" }],
        reward_rules: [{ required: true, validator: checkRule, trigger: "blur" }],
      },
      activeTimeOption: {
        // 大于今天
        disabledDate(date) {
          return dayjs(date).isBefore(dayjs().subtract(1, "day"));
        },
      },
      expireTimeOption: {
        // 包含今天
        disabledDate(date) {
          return dayjs(date).isBefore(dayjs());
        },
      },
    };
  },
  mounted() {
    this.getAppOption();
  },
  created() {
    if (this.isEdit) {
      const id = this.$route.query.id;
      this.id = id;
      this.fetchData(id);
    }
  },
  methods: {
    onChangeRule(rules) {
      this.postForm.reward_rules = rules;
    },
    async fetchData(id) {
      try {
        const { data } = await getPlanInfo(id);
        this.postForm = data.data;
        this.$set(this.postForm,'active_time',[data.data.effective_start_at,data.data.effective_end_at]);
      } catch (error) {
        console.log(error);
      }
    },
    async getAppOption() {
      try {
        const { data } = await getPublishedApp();
        this.appOptions = data.data.application;
      } catch (error) {
        console.log(error);
      }
    },
    handleSuccess() {
      this.$message({
        title: "成功",
        message: this.isEdit ? "编辑计划成功" : "发布计划成功",
        type: "success",
        duration: 2000,
      });
      this.$router.go(-1);
    },
    async submit() {
      const loadingInstance = this.$loading();
      let query = this.postForm;
      query.effective_start_at = query.active_time[0];
      query.effective_end_at = query.active_time[1];
      console.log("===submit", query);
      if (this.isEdit) {
        try {
          const { data } = await editPlan(this.id, query);
          loadingInstance.close();
          this.handleSuccess();
        } catch (error) {
          console.log(error.data.msg);
        }
      } else {
        try {
          const { data } = await createPlan(query);
          loadingInstance.close();
          this.handleSuccess();
        } catch (error) {
          console.log(error.data.msg);
        }
      }
    },
    onSubmit() {
      try {
        console.log(this.$refs.postForm.validate);
        this.$refs.postForm.validate((valid) => {
          if (valid) {
            this.submit();
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      } catch (error) {
        console.log(error);
      }
    },
    onCancel() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="less" scoped>
.news-detail {
  margin: 0;
  padding: 40px 20px;

  .title {
    width: 500px;
  }

  .desc {
    width: 1000px;
  }

  .pic {
    width: 500px;
  }
}
</style>
