<template>
  <div class="rule-table">
    <el-row>
      <el-col :span="8" class="rule-title">级别名称</el-col>
      <el-col :span="6" class="rule-title">邀请新用户数</el-col>
      <el-col :span="8" class="rule-title">{{ isPrecent ? "返佣比例" : "奖励金额" }}</el-col>
    </el-row>
    <div v-if="isPrecent">
      <el-row v-for="(item, index) in precentRows" :key="index">
        <el-col :span="8" class="rule-content"
          ><el-input
            v-model="item.name"
            @blur="checkname(item.name)"
            :disabled="disable"
            maxlength="15"
          ></el-input
        ></el-col>
        <el-col :span="6" class="rule-content"
          ><el-input
            v-model="item.min_number"
            @blur="checkMin(item, index)"
            :disabled="disable"
          ></el-input>
          ~
          <el-input
            v-model="item.max_number"
            @blur="checkRage(item, index)"
            :disabled="disable"
          ></el-input
        ></el-col>
        <el-col :span="8" class="rule-content"
          ><el-input
            v-model="item.reward_rate"
            @blur="checkPrecent(item.reward_rate)"
            :disabled="disable"
          ></el-input
          >%</el-col
        >
        <el-col :span="2" class="rule-icon">
          <i
            class="el-icon el-icon-circle-plus"
            v-if="precentRows.length <= 7"
            @click="addRow"
            :class="{ hidden: index !== precentRows.length - 1 || disable }"
          ></i>
          <i
            class="el-icon el-icon-remove"
            v-if="precentRows.length > 1"
            :class="{ hidden: index !== precentRows.length - 1 || disable }"
            @click="delRow(index)"
          ></i>
        </el-col>
      </el-row>
    </div>
    <div v-else>
      <el-row v-for="(item, index) in amountRows" :key="index">
        <el-col :span="8" class="rule-content"
          ><el-input v-model="item.name" @blur="checkname(item.name)" :disabled="disable"></el-input
        ></el-col>
        <el-col :span="6" class="rule-content"
          ><el-input
            v-model="item.min_number"
            @blur="checkMin(item, index)"
            :disabled="disable"
          ></el-input>
          ~
          <el-input
            v-model="item.max_number"
            @blur="checkRage(item, index)"
            :disabled="disable"
          ></el-input
        ></el-col>
        <el-col :span="8" class="rule-content"
          ><el-input
            v-model="item.reward"
            @blur="checkAmount(item.reward)"
            :disabled="disable"
          ></el-input>
        </el-col>
        <el-col :span="2" class="rule-icon">
          <i
            class="el-icon el-icon-circle-plus"
            v-if="amountRows.length <= 7"
            @click="addRow"
            :class="{ hidden: index !== amountRows.length - 1 || disable }"
          ></i>
          <i
            class="el-icon el-icon-remove"
            v-if="amountRows.length > 1"
            :class="{ hidden: index !== amountRows.length - 1 || disable }"
            @click="delRow(index)"
          ></i>
        </el-col>
      </el-row>
    </div>
    <el-alert v-show="errorList.length > 0" :title="errorList[0]" type="error" show-icon>
    </el-alert>
    <el-button type="primary" plain @click="handleRowChange" :disabled="disable"
      >规则提交</el-button
    >
    <span class="rule-tip">注：每次修改后需要重新提交规则</span>
    <div class="rule-p">
      <h4>字段说明：</h4>
      <p>
        级别名称：可以自定义级别名称<br />
        邀请新用户数：需要输入正整数，N代表无限大。如：0-10
        表示“大于等于0小于等于10”。必须从0开始，到N结束。各等级之间数值需连续，不允许断档。<br />
        返佣比例：输入正数，最多支持两位小数点，数值需小于100。如：12.34%<br />
        奖励金额：输入正数，最多支持8位小数点。<br />
      </p>
      <h4>举例：</h4>
      <p>
        级别、邀请用户数、返佣比例<br />
        L1、0-10、10%<br />
        L2、11-N、20%<br />
        当分销员“张三”邀请了新用户“李四”（李四是第9个新用户），此时“李四”支付了一笔金额100元，则分销员返佣比例为10%，返佣奖励10元；<br />
        当分销员“张三”已邀请11个新用户，此时“李四”又支付了一笔金额100元，则分销员返佣比例为20%，返佣奖励20元；<br />
      </p>
    </div>
  </div>
</template>

<script>
const NumRegex = /^[+]{0,1}(\d+)$/;
const BSVRegex = /^\d{1,6}(\.\d{1,8})?$/;
const PrecentRegex = /^(([1-9][0-9]?)|(0|[1-9][0-9]?)(?:\.\d{0,2}))?$/;
export default {
  props: {
    isPrecent: {
      type: Boolean,
      default: false,
    },
    disable: {
      type: Boolean,
      default: false,
    },
    rewardRules: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      loading: false,
      nameRule: "",
      minRule: "",
      maxRule: "",
      precentRule: "",
      amountRule: "",
      rowRule: "",
      defaultPrecent: [
        {
          name: "",
          min_number: "0",
          max_number: "",
          reward_rate: "",
        },
      ],
      defaultAmount: [
        {
          name: "",
          min_number: "0",
          max_number: "",
          reward: "",
        },
      ],
    };
  },
  computed: {
    precentRows: {
      get() {
        if (this.isPrecent) {
          return this.rewardRules.length ? this.rewardRules : this.defaultPrecent;
        } else {
          return this.defaultPrecent;
        }
      },
      set(val) {
        this.defaultPrecent = val;
      },
    },
    amountRows: {
      get() {
        if (!this.isPrecent) {
          return this.rewardRules.length ? this.rewardRules : this.defaultAmount;
        } else {
          return this.defaultAmount;
        }
      },
      set(val) {
        this.defaultAmount = val;
      },
    },
    errorList() {
      let arr = this.isPrecent
        ? [this.nameRule, this.minRule, this.maxRule, this.precentRule, this.rowRule]
        : [this.nameRule, this.minRule, this.maxRule, this.amountRule, this.rowRule];
      return arr.filter((x) => x);
    },
  },
  methods: {
    addRow() {
      if (this.isPrecent) {
        this.precentRows.push({
          name: "",
          min_number: "",
          max_number: "",
          reward_rate: "",
        });
      } else {
        this.amountRows.push({
          name: "",
          min_number: "",
          max_number: "",
          reward: "",
        });
      }
    },
    delRow(index) {
      if (this.isPrecent) {
        this.precentRows.splice(index, 1);
      } else {
        this.amountRows.splice(index, 1);
      }
    },
    checkname(val) {
      this.nameRule = val.length > 15 || val == "" ? "计划名称必填，且不超过15个字" : "";
    },
    checkMin(item, index) {
      let arr = this.isPrecent ? this.precentRows : this.amountRows;
      if (index == 0 && item.min_number != 0) {
        return (this.minRule = "必须从0开始");
      }
      if (item.min_number) {
        return (this.minRule = NumRegex.test(item.min_number) ? "" : "需要输入正整数");
      }
      if (index == 0 && item.max_number && arr.length > 1) {
        if (+item.max_number && +arr[index + 1].min_number - 1 != +item.max_number) {
          return (this.minRule = "邀请新用户数各等级之间数值需连续");
        }
      }
      if (index >= 1 && item.min_number && item.max_number) {
        if (+item.min_number && +arr[index - 1].max_number + 1 != +item.min_number) {
          console.log(+item.min_number, +arr[index - 1].max_number);
          return (this.minRule = "邀请新用户数各等级之间数值需连续");
        }
      }
    },
    checkRage(item, index) {
      let arr = this.isPrecent ? this.precentRows : this.amountRows;
      if (item.max_number) {
        this.maxRule =
          NumRegex.test(item.max_number) || item.max_number === "N" ? "" : "需要输入正整数";
      }
      if (index >= 1 && item.min_number && item.max_number) {
        // if (item.min_number >= item.max_number) {
        //   console.log(item.min_number, item.max_number, item.min_number >= item.max_number);
        //   return (this.maxRule = "邀请新用户数需从小到大");
        // }
        if (+item.min_number && +arr[index - 1].max_number + 1 != +item.min_number) {
          console.log(+item.min_number, +arr[index - 1].max_number);
          return (this.maxRule = "邀请新用户数各等级之间数值需连续");
        }
      }
    },
    checkPrecent(val, index) {
      if (+val >= 100 || +val <= 0) {
        return (this.precentRule = "返佣比例必填，且必须大于0小于100");
      }
      if (!PrecentRegex.test(val)) {
        return (this.precentRule = "返佣比例输入正数，最多支持两位小数点");
      } else {
        this.precentRule = "";
      }
    },
    checkAmount(val) {
      const isEmpty = val === "";
      const isCheck = BSVRegex.test(val);
      const isZoro = val === "0";

      if (isEmpty || isZoro) {
        return (this.amountRule = "奖励金额必填，且必须输入大于0");
      }

      return (this.amountRule = isCheck ? "" : "输入正数，最多支持8位小数点");
    },
    handleRowChange() {
      let arr = this.isPrecent ? this.precentRows : this.amountRows;
      let hasEnd = arr[arr.length - 1].max_number === "N";
      let pass = false;
      if (!hasEnd) {
        this.$message({ message: "数据未按规则填写请检查", type: "error" });
        return (this.rowRule = "规则邀新用户须以N结束");
      }
      arr.map((item, index) => {
        if (!Object.values(item).every((x) => x != "")) {
          this.$message({ message: "数据未按规则填写请检查", type: "error" });
          return (this.rowRule = `第${index + 1}行数据未按规则填写请检查`);
        } else {
          this.rowRule = "";
          pass = true;
        }
      });
      if (hasEnd && pass) {
        this.$emit("changeRule", arr);
        this.$message({ message: "提交成功，每次修改后需要重新提交规则", type: "success" });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.rule-title {
  text-align: center;
  background-color: rgba(0, 0, 0, 0.1);
}
.rule-content {
  padding: 5px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.rule-icon {
  display: flex;
  align-items: center;
  height: 47px;
}
.el-icon {
  font-size: 30px;
}
.hidden {
  display: none;
}
.rule-p {
  color: rgba(0, 0, 0, 0.6);
}
.rule-tip {
  color: rgba(0, 0, 0, 0.6);
  margin-left: 6px;
  font-size: 14px;
}
</style>
